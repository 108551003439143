import { YouTube } from '@mui/icons-material';
import { Grid, Grid2Props, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import Router from 'next/router';
import { FunctionComponent, memo } from 'react';

import microsoft from '../public/img/microsoft.png';
import styles from '../styles/Footer.module.css';
import { pages } from './Header';
import useBreakpoints from './Hooks/useBreakpoints';
import ModalCookies from './ModalCookies';
import ModalPrivacidade from './ModalPrivacidade';

const SocialGrid = styled(Grid)({
  borderRadius: '49px',
  background: '#4F4949',
  padding: '12px 24px',
  height: '48px',
  gap: '35px',
});

const GreenDot: FunctionComponent = () => {
  return <span style={{ color: 'var(--primary-green)' }}>•</span>;
};

const Setores: FunctionComponent = () => {
  const setores = pages['Setores'];

  return (
    <>
      <Grid item className={'barlow--size18 bold white'}>
        Setores
      </Grid>
      {setores &&
        setores.map(sub => (
          <Grid
            style={{ cursor: 'pointer' }}
            onClick={() => Router.push(sub.path)}
            item
            key={sub.label}
            className={'barlow--size18 white'}
          >
            {sub.label}
          </Grid>
        ))}
    </>
  );
};

const Others: FunctionComponent<{ page: string }> = ({ page }) => {
  return (
    <>
      <Grid item className={'barlow--size18 bold white'}>
        {page}
      </Grid>
      {pages[page].map(sub => (
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => Router.push(sub.path)}
          item
          key={sub.label}
          className={'barlow--size18 white'}
        >
          {sub.label}
        </Grid>
      ))}
    </>
  );
};

const Parceiros: FunctionComponent = () => {
  return (
    <Grid
      container
      item
      direction={'column'}
      xs={12}
      lg={2}
      borderLeft={{ xs: '0px', lg: '1px solid rgba(136, 154, 165, 0.1)' }}
      padding={{ xs: '', lg: '32px 0px 0px 3.89%' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap="16px"
    >
      <Grid item>
        <span className={'barlow--size18 bold white'}>Parceiros</span>
      </Grid>
      <Grid item component={Link} href="https://infobip.com/">
        <Image alt="Infobip" src="/img/infobip.webp" width={118} height={40} />
      </Grid>
      <Grid item component={Link} href="https://www.zenvia.com/">
        <Image alt="Zenvia" src="/img/zenvia.webp" width={169} height={40} />
      </Grid>
      <Grid
        item
        component={Link}
        href="https://www.microsoft.com/pt-br/startups/"
      >
        <Image alt="Microsoft" src={microsoft} />
      </Grid>
    </Grid>
  );
};

const WhiteLogo: FunctionComponent = () => {
  return (
    <Grid
      item
      xs={12}
      lg={'auto'}
      justifyContent={{ xs: 'center', lg: 'flex-start' }}
      maxWidth={{ xs: '108px', lg: '204px' }}
      maxHeight={{ xs: '40', lg: '75px' }}
    >
      <Image
        alt="Devyx"
        src="/img/devyx-footer.webp"
        width={'128px'}
        height={'47px'}
      />
    </Grid>
  );
};

const Politicas: FunctionComponent = () => {
  return (
    <Grid
      item
      container
      xs={12}
      lg={'auto'}
      justifyContent={'center'}
      gap={{ xs: '24px', lg: '32px' }}
    >
      <Grid item component={ModalPrivacidade} software={undefined} />
      <Grid item component={ModalCookies} />
    </Grid>
  );
};

const FooterLine: FunctionComponent<Grid2Props> = props => {
  return (
    <Grid
      {...props}
      borderBottom={{
        xs: '1px solid rgba(136, 154, 165, 0.1)',
        lg: '0px',
      }}
      item
      xs={12}
      width="100%"
      height="1px"
    />
  );
};

const Footer: FunctionComponent = () => {
  const { isDesktop } = useBreakpoints();
  return (
    <footer className={styles.footer}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {!isDesktop && (
          <>
            <WhiteLogo />
            <FooterLine margin={'32px 0px'} />
          </>
        )}
        <Grid
          container
          item
          flexDirection={{ xs: 'column', md: 'column', lg: 'row' }}
          justifyContent={{ xs: 'center', lg: 'space-between' }}
          alignItems="center"
          alignContent="center"
          xs={12}
          height={{ xs: '184px', lg: '155px' }}
          minHeight={{ xs: '184px', lg: '155px' }}
          gap={{ xs: '32px', lg: '0px' }}
        >
          {isDesktop && <WhiteLogo />}
          <SocialGrid
            item
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems="center"
            xs={'auto'}
          >
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={() => Router.push('https://www.facebook.com/devyx.tech')}
              item
            >
              <Image
                src={'/img/facebook.webp'}
                alt="facebook"
                width={24}
                height={24}
              />
            </Grid>
            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={() =>
                Router.push('https://www.instagram.com/devyx.tech')
              }
            >
              <Image
                src={'/img/instagram.webp'}
                alt="instagram"
                width={24}
                height={24}
              />
            </Grid>
            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={() =>
                Router.push('https://www.linkedin.com/company/devyxtech')
              }
            >
              <Image
                src={'/img/linkedin.webp'}
                alt="linkedin"
                width={24}
                height={24}
              />
            </Grid>
            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={() => Router.push('https://www.youtube.com/@devyx-tech')}
            >
              <YouTube color="secondary" />
            </Grid>
          </SocialGrid>
          <Grid
            item
            xs={'auto'}
            className="barlow--size16 white"
            textAlign={{ xs: 'center', lg: 'right' }}
          >
            DEVYX CONSULTORIA E DESENVOLVIMENTO DE TECNOLOGIA LTDA
            <br />
            13.143.988/0001-90
            <br />
            Rua Coronel Frederico Coelho, 345 Sala 201{' '}
            {isDesktop && <GreenDot />}
            <br /> Centro <GreenDot /> São Gotardo-MG <GreenDot /> Brasil{' '}
            {isDesktop ? '-' : <br />} CEP: 38800-000
          </Grid>
        </Grid>
        {!isDesktop && <FooterLine margin={'32px 0px'} />}
        <Grid
          container
          item
          direction={'row'}
          justifyContent={{ xs: 'center', lg: 'flex-start' }}
          borderTop={{ lg: '1px solid rgba(136, 154, 165, 0.1)' }}
          borderBottom={{ xs: '0px', lg: '1px solid rgba(136, 154, 165, 0.1)' }}
          height={{ xs: '280px', lg: '366px' }}
          minHeight={{ xs: '280px', lg: '366px' }}
          xs={12}
          className={'barlow--size16 white'}
          gap={{ xs: '56px', lg: '0px' }}
        >
          {isDesktop && (
            <Grid
              container
              item
              direction={'row'}
              xs={10}
              className={'barlow--size18 white'}
              padding="32px 0px 38px 0px"
              justifyContent={'space-around'}
            >
              {Object.keys(pages).map(page => {
                return (
                  page.toLowerCase() != 'setores' && (
                    <Grid
                      container
                      item
                      direction={'column'}
                      key={page}
                      spacing={1}
                      xs={3}
                    >
                      <Others page={page} />
                      {page.toLowerCase() == 'muito prazer, devyx!' && (
                        <Setores />
                      )}
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}
          <Parceiros />
          {!isDesktop && <Politicas />}
        </Grid>
        {!isDesktop && <FooterLine margin={'16px 0px'} />}
        <Grid
          container
          item
          direction={'row'}
          className={'barlow--size16 white'}
          height={{ xs: '120px', lg: '72px' }}
          minHeight={{ xs: '120px', lg: '72px' }}
          alignContent={'center'}
          xs={12}
          justifyContent={{ xs: 'center', lg: 'space-between' }}
        >
          {isDesktop && <Politicas />}
          <Grid
            item
            container
            xs={12}
            lg={'auto'}
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
            gap={{ xs: '16px', lg: '76px' }}
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            <Grid item>© 2022 Devyx®. Todos os direitos reservados</Grid>
            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={() => Router.push('https://somocollab.com/')}
              className={'barlow--size16 white'}
            >
              <u>
                Produzido por <span>Somo Collab</span>
              </u>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default memo(Footer);
